import React, { Component } from 'react';


class TermsCondition extends Component {

	constructor(props) {
		super(props);   		
		this.state = {
			isLoading: false,     
		}
	}


	render(){      		
		return(
			<div className="content-wrapper">
                <section className="mt-5 mb-5">
					<div className="container">
						<div className="row">
                            <div className="col-12 col-lg-12">
                            <section>
                                <h2 className='mb-3' style={{'textAlign':'center'}}>Terms Of Service</h2>
                                <p>The terms of service imply a legal agreement (hereinafter, “Agreement”) between CLS, with offices at Riyadh and you. Use of Service offered by CLS with all associated Software, requires acceptance of the terms and conditions specified herein. Use of any Services offered by CLS, either by downloading, installing, or using software or apps of CLS is an explicit, legally acceptable acknowledgement and consent to terms and conditions, including future amendments and inclusions. </p>
                                <p>
                                CLS is entitled to alter the terms and conditions of this Agreement at any time in the future. All such changes will be effective from the date of publication of changes. Parties to the agreement are to periodically review the published terms of service. Use of the Service after such changes imply acceptance of all modifications.
                                </p>
                                <p>
                                CLS is not in the business of logistics or courier services. Courier services or logistics services, are facilitated through the software or apps, and all such services are in the ambit of third-party couriers or logistics providers or fleet operators. CLS disclaims any responsibility or liability for any such services other than those specifically mentioned as offered.  
                                </p>
                            </section>
                            <section>
                                <h2>Representations and Warranties</h2>
                                <p>By using CLS Software or Service, you claim legal capacity to enter into this Agreement. All Services and Software are not for use by individuals below the age of 18. CLS Service and Software is for personal use only. Access to other individuals, other than the authorized or registered user is not permitted. Users are to furnish proof of identity to CLS when required/requested. </p>
                            </section>
                            <section>
                                <h2>User Account</h2>
                                <p>You will be the authorized user of the created account. The responsibility for ensuring confidentiality of password or credentials lies with you. All activities from the account are the sole responsibility of the user. Any suspected unauthorized access is to be intimated to CLS immediately.</p>
                            </section>
                            <section>
                                <h2>Third Party</h2>
                                <p>During usage of the Service, you may interact with third-party service providers, for other services. All such interactions will be solely between you and the respective third party. CLS is not liable or responsible for interactions with third-party providers. CLS does not promote or endorse websites.  Users unwilling to view advertising may be charged a higher fee for Services. </p>
                            </section>
                            <section>
                                <h2>Products</h2>
                                <p>Certain products have restrictions in respective jurisdictions. Users are to abide by all legal requirements and submit to restrictions in place. </p>
                            </section>
                            <section>
                                <h2>Indemnification</h2>
                                <p>CLS Company, and employees, agents, and affiliates are indemnified from any losses, claims, damages, penalties, fines, and expenses arising out of unauthorized use. This includes legal fees and expenses. </p>
                            </section>
                            <section>
                                <h2>Disclaimer</h2>
                                <p>CLS makes no warranties or representations regarding accuracy of content displayed through the software or any linked websites.  </p>
                            </section>
                            <section>
                                <h2>Internet </h2>
                                <p>The Service relies on Internet and electronic communications and CLS is not responsible for any delays, failures, or damages arising out of internet related problems.  </p>
                            </section>
                            <section>
                                <h2>Limitation of Liability </h2>
                                <p>In the event of a claim, CLS’s liability will not exceed the total amount of the previous six (6) months preceding the event necessitating the claim. CLS is not liable for any indirect, incidental, consequential, or other damages, including personal injury, data or economic loss of any kind. CLS is not liable for any damage, or injury arising out use of the service or software, or any inability to use the same. </p>
                            </section>
                            <section>
                                <h2>Dispute  </h2>
                                <p>Disputes related to this Agreement are to be resolved amicably through mediation between both parties. Mediation will be through mutually acceptable mediators, and unresolved disputes will be settled by binding arbitration in the jurisdiction of Riyadh. </p>
                            </section>

                            <section>
                                <h2>Termination </h2>
                                <p>CLS may modify or discontinue the Software or Services, or terminate your access, wherever necessary, with or without notice and without liability. CLS is also entitled to initiate legal action, whenever required, for suitable remedies. You may terminate this Agreement at any time by discontinuing use of the Service or Software.  </p>
                            </section>
                            </div>                        
                        </div>
                    </div>
                </section>                				            
        	</div>
		)
	}
}
export default TermsCondition;
