import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { ADMIN_API_BASE_ENDPOINT } from '../configs/AppConfig';

class PaymentThankYou extends Component {

	constructor(props) {
		super(props);   
      
        const query = new URLSearchParams(props.location.search);
		const payment_id = query.get('id');
		const checkout_id = query.get('checkoutId');    
		this.state = {
			isLoading: true,  
            payment_id: payment_id,
            checkout_id: checkout_id,  
            payment_success: false,          
            payment_fail: false,          
		}

	}

    componentDidMount = async () => {	
      
		if( this.state.checkout_id && this.state.payment_id){
       
            const requestParam = {               
                checkout_id: this.state.checkout_id,
                payment_id: this.state.payment_id,                      
            }
            this.fetchPaymentStatus(requestParam);
        }

    }

    fetchPaymentStatus = async ( requestParam ) => {
        
		await axios.post(ADMIN_API_BASE_ENDPOINT + 'checkout/payment_status', requestParam).then( async (response) => {    
            console.log(response)
			if(response.data.success === true){			
				this.setState({ payment_success: true, payment_fail: false, isLoading: false});
			} else {
                this.setState({ payment_success: false, payment_fail: true, isLoading: false });
            }
			     
		});
	};


	render(){      		
		return(
			<div className="content-wrapper">
                <section  style={{"marginTop":"150px","marginBottom":"150px"}}>
					<div className="container">
                        {
                            this.state.payment_success === true &&
                            <div className="row">
                                <div className="col-12 col-lg-12">
                            
                                    <div className="payment-thank-you-container">
                                        <div className="payment-thank-you-content">
                                        
                                            <h1>Thank You For Your Payment!</h1>
                                            <p>Your payment was successfully processed.</p>
                                            <p>We are excited to have you as a customer and look forward to serving you.</p>
                                            <p>An email with your payment details and receipt has been sent to your email address.</p>
                                            <p>If you have any questions or need further assistance, please feel free to contact us.</p>
                                            <Link to="/" className="btn btn-primary">Back to Home</Link>
                                        </div>
                                    </div>
                            
                                </div>                        
                            </div>
                        }
                        {
                            this.state.payment_fail === true && 
                            <div className="row">
                                <div className="col-12 col-lg-12">
                            
                                    <div className="payment-thank-you-container">
                                        <div className="payment-thank-you-content">
                                        
                                            <h1>Payment Failed</h1>
                                            <p>Sorry, your payment could not be processed.</p>
                                            <p>Please check your payment details and try again.</p>
                                            <p>If you have any questions or need further assistance, please feel free to contact us.</p>
                                            <Link to="/" className="btn btn-primary">Back to Home</Link>
                                        </div>
                                    </div>
                            
                                </div>                        
                            </div>
                        }
                        {
                            this.state.isLoading === true &&
                                <div style={{ marginTop: '1rem', padding: '1rem', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9' }}>
                                <p style={{ marginBottom: '0.5rem', fontSize: '1.1rem' }}>Your payment is being processed.</p>
                                <p style={{ marginBottom: '0.5rem', color: 'gray' }}>Please do not close or refresh this page.</p>
                                <p style={{ marginBottom: '0', fontSize: '0.9rem' }}>This may take a few moments...</p>
                            </div>
                        }
						 
                    </div>
                </section>                				            
        	</div>
		)
	}
}
export default PaymentThankYou;
