const dev = {
	DOMAIN_URL: 'http://localhost:3000/',
	API_ENDPOINT_URL: 'http://localhost:9000/',
	SOCKET_URL: 'http://localhost:3002/'  
};

const test = {
	DOMAIN_URL: 'https://becls.com/',
	API_ENDPOINT_URL: 'https://api.becls.com/',
	SOCKET_URL: 'https://api.becls.com:9000'  
};

const prod = {
	DOMAIN_URL: 'https://becls.com/',
	API_ENDPOINT_URL: 'https://api.becls.com/',
	SOCKET_URL: 'https://api.becls.com:9000'  
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
