import React, { Component } from 'react';


class PrivacyPolicy extends Component {

	constructor(props) {
		super(props);   		
		this.state = {
			isLoading: false,     
		}
	}


	render(){      		
		return(
			<div className="content-wrapper">
                <section className="mt-5 mb-5">
					<div className="container">
						<div className="row">
                            <div className="col-12 col-lg-12">
                            <section>
                                <h2 className='mb-3' style={{'textAlign':'center'}}>Privacy Policy</h2>
                                <p>This “Privacy Notice” explains how CLS, including affiliates (collectively, “CLS”, “we”, “us”, or “our”) handles personal data. This explains the rights and the choices available to visitors, regarding personal data. Personal data refers to information of an identifiable individual that may be either shared by the individual or collected by our website when a user visits our website or uses our services.</p>
                                <p>
                                This Privacy Policy was last updated on April 22, 2024, and could be subject to changes in the future to comply with new legal requirements or to meet evolving needs of our business.
                                </p>
                            </section>
                            <section>
                                <h2>Overview</h2>
                                <p>CLS provides logistic technology services to Sellers and their customers and this Privacy Policy provides a general overview of the manner in which your personal data is handled. CLS does not share personal or professional information of visitors or users with any third party. Any processing of personal data by CLS, including details such as name, contact number, email address of visitors to our website shall always be as per governing regulations in our areas of operation. </p>
                                <p>
                                Additionally, jurisdiction-specific information that is applicable for users from specific locations such as EU/EEA or applicable US Data Privacy laws are outlined in this Privacy Law.
                                </p>
                                <p>
                                CLS has implemented multiple processes and procedures to ensure complete protection of data submitted and processed through this website, meeting the requirements as a processor.
                                </p>
                                <p>
                                In the event of any queries regarding this privacy policy, please contact our Data Protection Officer at:
                                </p>
                                <p>
                                    <b>Email address</b> – <a href='mailto:info@cloudlogisticsolution.com'>info@cloudlogisticsolution.com</a>  
                                </p>
                                <p>
                                    <b>Address</b> - Khalid ibn alwaleed street, Riyadh, Saudi Arabia
                                </p>
                            </section>
                            <section>
                                <h2>Our audiences</h2>
                                <p>Our audiences are defined on the basis of different levels of interaction.</p>
                                <h6>Visitors to Website (referred to as “Visitors”)</h6>
                                <p>If you have visited our website but have not filled any form sharing your details, then you will be classified as a “Visitor”.</p>
                                <h6>Respondents</h6>
                                <p>If you have interacted and shared your details with us to contact you, you will be classified as a “Respondent”.</p>
                                <h6>Client</h6>
                                <p>If you have been availing our services, you will be classified as our “Client”.</p>
                            </section>
                            <section>
                                <h2>Controller and Processor</h2>
                                <p>CLS is both a Data Processor and Controller as per GDPR definitions.</p>
                            </section>
                            <section>
                                <h2>Data Protection Officer</h2>
                                <p>CLS has a Data Protection Officer (also referred to as “DPO”) who will be responsible for privacy and data protection of all Visitors, Respondents and Clients. </p>
                            </section>
                            <section>
                                <h2>How is your data collected?</h2>
                                <p>CLS collects your data with the help of third party tools like Google Analytics. These tools do not retain any of your information. Also, these tools are not legally permitted to store or use your data. All data and information pertaining to you remains only with CLS as required. Data is collected in the following manner.</p>
                                <h6>Cookie Data</h6>
                                <p>Cookies are text files with snippets of information sent by a website to a Visitor's hard disk. Cookies cannot run programs or deliver viruses to your computer. By visiting any website, you permit cookies to reside on your device. A temporary cookie may also be used on protected and unprotected sections of our site to send information to grant access to gated content. This ensures that the user does not have to provide data repeatedly on multiple pages. Temporary cookies will not reside on the hard drive of a user’s computer, and will only be active during ongoing sessions; till the termination of the session. Cookies are activated only during visits to the website and do not track activity after the user leaves the website.</p>
                                <h6>Google Analytics</h6>
                                <p>Google Analytics collects information related to pages visited, time spent on pages and other standard log information. Personally Identifiable Information (PII) that you share with CLS is not accessed by Google Analytics.</p>
                                <h6>Lead Form</h6>
                                <p>Visitors may share contact information by filling up the lead forms. These forms usually contain inputs such as name, contact number and email address. This information is used to reach out to the person, to either schedule a demo or to clarify terms and conditions. All the information shared through lead forms will always remain with CLS</p>
                            </section>
                            <section>
                                <h2>External Links</h2>
                                <p>This website may include hyperlinks to external websites that may not be controlled by CLS. Though reasonable care has been exercised in providing such links, all are advised to exercise caution before clicking on any of these external links. The safety of external links are not guaranteed, and CLS does not constantly verify the safety of the contents on the linked websites, as this may continually change. Clicking of external links is at your own risk and CLS is not responsible for any damages or consequences thereof, when you click such links</p>
                            </section>
                            <section>
                                <h2>Sensitive Personal Data</h2>
                                <p>GDPR lists our categories of personal data considered to be “sensitive”, mandating special consideration by Data Controllers. This website, does not intentionally collect or process any such sensitive personal data.</p>
                            </section>
                            <section>
                                <h2>Children's Personal Data</h2>
                                <p>This website, is not for children below the age of 13. If you are aware of any child below the age of 13 who may have provided us with personal information without parental consent, please contact the CLS Data Protection Officer to enable use to take suitable action</p>
                            </section>
                            <section>
                                <h2>Legal Basis for using your data</h2>
                                <p>Everything that we do with information about individuals – such as obtaining, storing, using, or deleting information – has an acceptable legal basis. We are also obligated to reveal information without prior permission if required by law in furtherance of any legal proceedings, or if there is sufficient cause that the person has infringed or is infringing rights and property belonging to CLS or others, where such activity by the user could deliberately or otherwise harm or transgress the rights and property of others including CLS.  </p>
                            </section>
                            <section>
                                <h2>Acceptance of these conditions</h2>
                                <p>We assume that all Visitors, Respondents and Clients have understood our Policy. Queries may be addressed to our Data Protection Officer. If you disagree with this Policy, kindly refrain from using our website and our services.</p>
                            </section>
                            <section>
                                <h2>Changes to Privacy Policy</h2>
                                <p>We may change this policy from time to time. You are advised to check our intimation regarding such changes. If you continue to use our website or services after such changes to policies, it is a indication that you are aware of the revision(s) to our policy.</p>
                            </section>
                            </div>                        
                        </div>
                    </div>
                </section>                				            
        	</div>
		)
	}
}
export default PrivacyPolicy;
