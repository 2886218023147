import { env } from './EnvironmentConfig'

export const APP_NAME = 'Cloud Logistics';
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const ADMIN_API_BASE_ENDPOINT = API_BASE_URL + 'api/';
export const PER_PAGE_DATA = 100;
export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const ONLY_DATE_FORMAT = 'YYYY-MM-DD';
export const ONLY_TIME_FORMAT = 'HH:mm';
export const AWS_S3_URL = '';
export const SOCKET_URL = '';
export const DOMAIN_URL =env.DOMAIN_URL;