import React from 'react';
import { Link } from "react-router-dom";
import { DOMAIN_URL } from '../configs/AppConfig';

const Header = (props) => {
    return(
        <header>
            <div className="container">
                <nav className="navbar justify-content-between">
                    <Link className="navbar-brand" to="/"><img width={150} className="img-fluid" src={DOMAIN_URL + "images/logo.svg"} alt="" /></Link>
                    <div className="right-part">
                        {/* <a className="btn btn-outline-primary contact-us-btn" href="#">Contact Us</a> */}
                        <Link className="btn btn-primary get-started-btn"  to="/">Get Started</Link>
                    </div>    
                </nav>
            </div>
        </header>
    )
}

export default Header;