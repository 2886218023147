import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ReactGA from 'react-ga4';
import * as Yup from 'yup';
import countriesData from './data/countries.json';
import 'react-phone-number-input/style.css';
import PhoneInputField from './PhoneInputField';
import axios from 'axios';
import { ADMIN_API_BASE_ENDPOINT } from './configs/AppConfig';


const validationSchema = Yup.object().shape({
	full_name: Yup.string().required('Full Name is required'),	 
	shop_name: Yup.string().required('Shop Name is required'),	 
	description: Yup.string().required('Description is required'),	 
	category: Yup.string().required('Category is required'),	 
	country: Yup.string().required('Country is required'),	 
	no_employee: Yup.string().required('Employee is required'),	 
	reference: Yup.string().required('Reference is required'),	 
	phoneNumber: Yup.string().required('Phone Number is required'),	 	
	terms: Yup.string().required(),	 
	email: Yup.string().required('Email is required').email('Invalid email address'),
});

class Home extends Component {

	constructor(props) {
		super(props);   		
		this.state = {
			isLoading: false,     
		}
	}

	handleSubmit = async (values,  { setSubmitting, setStatus, resetForm  }) => {

		ReactGA.event({
			category: "Lead",      // Event category (required)
			action: "Submited Lead", // Event action (required)
			label: "Submit Lead"  // Event label (optional)
		  });
	
		this.setState({
			isLoading: true
		});
		await axios.post(ADMIN_API_BASE_ENDPOINT + 'lead/lead_info', values).then((response) => {   						
			if(response.data.code === 400){
				this.setState({
					error: true,  
					success: false,    
					isLoading: false,    
					error_message: response.data.error,
					success_message: ''       
				}); 				
			} else {
				this.setState({
					error: false,  
					success: true,   
					isLoading: false,     
					success_message: response.data.message,
					error_message: '',					
				}); 	
				resetForm()			
			}			       
		});

	};

	render(){      		
		return(
			<div className="content-wrapper">
				<section className="top-section" style={{ "backgroundImage": 'url(images/Banner_Animation.gif)','backgroundSize': 'cover'}}>
					<div className="container">
						<div className="row" >
							<div className="col-12 col-lg-9" >
								
							</div>
							<div className="col-12 col-lg-3">
								<div className="form-box">
									<h3 className="color-blue">Almost there!</h3>
									<p>Learn how this solution will transform operations and efficiency. Fill details for free consultation.</p>
									<Formik
										initialValues={
											{ 
												full_name: '', 
												email: '', 
												shop_name: '', 
												description: '', 
												category: '', 
												country: '',
												no_employee: '',
												reference: '',
												terms: '',
												phoneNumber: '',
												
											}
										}
										validationSchema={validationSchema}
										validateOnChange={false}
										validateOnBlur={false}
										onSubmit={this.handleSubmit}
									>

										{(formik, setFieldValue, status, isSubmitting  ) => (
										<Form>
											<div className="form-group">
												<Field 
													type="text" 
													className={formik.touched.full_name && formik.errors.full_name ? 'form-control error' : 'form-control'}
													placeholder="Full Name"  
													name="full_name" 													
												/>	
												  <ErrorMessage name="full_name" component="span" className="error-message" />										
											</div>
											<div className="form-group">	
												<Field 
													type="email" 
													className={formik.touched.email && formik.errors.email ? 'form-control error' : 'form-control'}
													placeholder="Email"  
													name="email" 													
												/>	
												 <ErrorMessage name="email" component="span" className="error-message"/>																										
											</div>
											<div class="form-group">
												<Field 
													type="text" 
													className={formik.touched.shop_name && formik.errors.shop_name ? 'form-control error' : 'form-control'}
													placeholder="Shop Name"  
													name="shop_name" 													
												/>
												 <ErrorMessage name="shop_name" component="span" className="error-message"/>
											</div>
											<div className="form-group">
												<Field 
													as="select"
													name="category"
													className={formik.touched.category && formik.errors.category ? 'form-control error' : 'form-control'}
												>
													<option key={''} value=''>Select category</option>
													<option key='Electronics' value='Electronics'>Electronics</option>
													<option key='Apparel and accessories' value='Apparel and accessories'>Apparel and accessories</option>
													<option key='Footwear and accessories' value='Footwear and accessories'>Footwear and accessories</option>
													<option key='Courier, Parcel & Logistics' value='Courier, Parcel & Logistics'>Courier, Parcel & Logistics</option>
													<option key='eCommerce' value='eCommerce'>eCommerce</option>
													<option key='Restaurant' value='Restaurant'>Restaurant</option>
													<option key='Retail' value='eCommerce'>Retail</option>
													<option key='Beauty & Personal Care' value='Beauty & Personal Care'>Beauty & Personal Care</option>
													<option key='Fashion' value='Fashion'>Fashion</option>
													<option key='Food & Drink' value='Food & Drink'>Food & Drink</option>
													<option key='Books' value='Books'>Books</option>
													<option key='Health & Wellness' value='Health & Wellness'>Health & Wellness</option>
													<option key='Travel' value='Travel'>Travel</option>
													<option key='Education' value='Education'>Education</option>
													<option key='Automotive' value='Automotive'>Automotive</option>
													<option key='Home & Garden' value='Home & Garden'>Home & Garden</option>
													<option key='Sports & Fitness' value='Sports & Fitness'>Sports & Fitness</option>
													<option key='Pets' value='Pets'>Pets</option>
													<option key='Hobbies & Leisure' value='Hobbies & Leisure'>Hobbies & Leisure</option>
													<option key='Any other' value='Any other'>Any other</option>																															
												</Field>
												<ErrorMessage name="category" component="span" className="error-message"/>
											</div>
											<div className="form-group">
												<Field 
													as="select"
													name="country"
													className={formik.touched.country && formik.errors.country ? 'form-control error' : 'form-control'}
												>
														<option key={''} value=''>Select Country</option>
														{countriesData.countries.map(option => (
														<option key={option.name} value={option.name}>
															{option.name}
														</option>
														))}
												</Field>
												<ErrorMessage name="country" component="span" className="error-message"/>
											</div>											

											<div className="form-group">
												<PhoneInputField  name="phoneNumber"  defaultCountry="SA"/>											
											</div>
											
											<div class="form-group">
												<Field 
													type="text" 
													className={formik.touched.description && formik.errors.description ? 'form-control error' : 'form-control'}
													placeholder="Description"  
													name="description" 													
												/>
												 <ErrorMessage name="description" component="span" className="error-message"/>
											</div>

											<div className="form-group">
												<Field 
													as="select"
													name="no_employee"
													className={formik.touched.no_employee && formik.errors.no_employee ? 'form-control error' : 'form-control'}
												>
													<option key={''} value=''>Select Employee</option>
													<option key='5-10' value='5-10'>5-10</option>
													<option key='10-50' value='10-50'>10-50</option>
													<option key='50-200' value='50-200'>50-200</option>
													<option key='200-500' value='200-500'>200-500</option>													
													<option key='Above 1000' value='Above 1000'>Above 1000</option>																																								
												</Field>
												<ErrorMessage name="no_employee" component="span" className="error-message"/>
											</div>

											<div className="form-group">
												<Field 
													as="select"
													name="reference"
													className={formik.touched.reference && formik.errors.reference ? 'form-control error' : 'form-control'}
												>
													<option key={''} value=''>Select Reference</option>
													<option key='Advertisement' value='Advertisement'>Advertisement</option>
													<option key='Friends' value='Friends'>Friends</option>
													<option key='Social Media' value='Social Media'>Social Media</option>
													<option key='Any Other' value='Any Other'>Any Other</option>																																																			
												</Field>
												<ErrorMessage name="reference" component="span" className="error-message"/>
											</div>
											
											<div className="form-check">
											<label>
												<Field className="form-check-input" type="checkbox" name="terms"/>
												<label className="form-check-label" for="terms">
													Read our <a target='_blank' href="/term/">Terms and conditions</a> and <a target='_blank' href="/policy/">Privacy Policy</a> for availing our services. 
												</label>
												<ErrorMessage name="terms" component="span" className="error-message"/>
											</label>
																								
											</div>

											{this.state.error_message && <span className="form-error error-message">{this.state.error_message}</span>}
											{this.state.success_message && <span className="form-error success-message error-message">{this.state.success_message}</span>}

											<button type="submit" className="btn btn-primary w-100" disabled={this.state.isLoading}>
												{this.state.isLoading === true ? 'Submitting...' : 'Get Started'}
											</button>											
										</Form>
										)}
									</Formik>
								</div>
							</div>
							{/* <img className="img-fluid top-section-img" src="images/img/top-section.svg" alt="" /> */}
						</div>
					</div>
				</section>
				<section class="who-we-are-section section-padding">
					<div class="container">
						<div class="row align-items-center">
							<div class="col-12 col-lg-6">
								<div class="video-box">
									<div class="video-inner">
										<video autoPlay loop  playSinline>
											<source src="images/intro.mp4" type="video/mp4"/>
										</video>
										<div class="video-bg"></div>
									</div>
								</div>
							</div>
							<div class="col-12 col-lg-6">
								<div class="inner">
									<div class="section-title text-left decoration-left">
										<h2 className="tit">
										
											Reliable, end-to-end <span className="color-blue">on-demand</span> Delivery Solution
										</h2>
									</div>
									<a className="btn btn-primary" href="#">Watch Demo</a>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="businesses-deliver-section section-padding">
					<div className="container">
						<div className="section-title text-center">
							<h2 className="tit">
								<span className="small">Platform</span>
								Fully integrated,  <span className="different-color">intuitive platform </span> for  <br></br>Streamlined deliveries throughout Order Lifecycle.
							</h2>
						</div>
						<div className="inner-wrapper">
							<div className="row">
								<div className="col-12 col-sm-6 col-lg-4">
									<div className="card bg-one">
										<div className="card-body color-white">
											<div className="card-image bg-one-shade">
												<img className="img-fluid" src="images/img/icon/icon01.svg" alt="icon" />
											</div>
											<h4 className="card-title">Remote fleet monitoring</h4>
											<p className="card-text">Fully integrated telematics solutions for remote monitoring of fleet.</p>
										</div>
									</div>
								</div>
								<div className="col-12 col-sm-6 col-lg-4">
									<div className="card bg-two">
										<div className="card-body">
											<div className="card-image bg-two-shade">
												<img className="img-fluid" src="images/img/icon/icon02.svg" alt="icon" />
											</div>
											<h4 className="card-title">Automated, optimized order fulfillment</h4>
											<p className="card-text">Intelligent solution to optimize order fulfillment, end-to-end order management.</p>
										</div>
									</div>
								</div>
								<div className="col-12 col-sm-6 col-lg-4">
									<div className="card bg-three">
										<div className="card-body">
											<div className="card-image bg-three-shade">
												<img className="img-fluid" src="images/img/icon/icon03.svg" alt="icon" />
											</div>
											<h4 className="card-title">Analysis, critical thinking</h4>
											<p className="card-text">Complete visibility over operations, one-touch report generation, analysis.</p>
										</div>
									</div>
								</div>
								<div className="col-12 col-sm-6 col-lg-4">
									<div className="card bg-four">
										<div className="card-body">
											<div className="card-image bg-four-shade">
												<img className="img-fluid" src="images/img/icon/icon04.svg" alt="icon" />
											</div>
											<h4 className="card-title">Product portfolio updates</h4>
											<p className="card-text">Eliminate third parties for inventory, product portfolio updates on microsite.</p>
										</div>
									</div>
								</div>
								<div className="col-12 col-sm-6 col-lg-4">
									<div className="card bg-five">
										<div className="card-body">
											<div className="card-image bg-five-shade">
												<img className="img-fluid" src="images/img/icon/icon05.svg" alt="icon" />
											</div>
											<h4 className="card-title">Streamline personnel management</h4>
											<p className="card-text">Update fleet personnel information and map vehicle handling metrics. </p>
										</div>
									</div>
								</div>
								<div className="col-12 col-sm-6 col-lg-4">
									<div className="card bg-six">
										<div className="card-body">
											<div className="card-image bg-six-shade">
												<img className="img-fluid" src="images/img/icon/icon06.svg" alt="icon" /> 
											</div>
											<h4 className="card-title">Unified dashboard</h4>
											<p className="card-text">Single dashboard for user, account management, visualization an tracking performance</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="key-features-section section-padding section-bg">
					<div className="container">
						<div className="row">
							<div className="col-12 col-lg-6">
								<img className="img-fluid" src="images/img/img1.svg" alt="" />
							</div>
							<div className="col-12 col-lg-6">
								<div className="pl-60">
									<div className="section-title text-left decoration-left">
										<h2 className="tit">
											<span className="small">Key Features</span>
											Fully Integrated Cloud<br></br>Native Logistic System
										</h2>
									</div>
									<p>Stop hopping across solutions for different phases of your operations - order, delivery partners, asset management. Embrace efficiency and seamless operations with a single solution for the entire lifecycle of operations. Enjoy complete control and transparency of operations, by eliminating patchwork of solutions for flawless operations and improved customer satisfaction. </p>
									<h6>Key Features:</h6>
									<ul className="key-features-list">
										<li><b>Integrated portfolio upload</b></li>
										<li><b>Display ads</b></li>
										<li><b>User onboarding</b></li>
										<li><b>Vehicle assets management</b></li>
										<li><b>Order mapping, order assignment, </b></li>
										<li><b>Real-time vehicle tracking, vehicle maintenance, and analytics </b></li>
										
									</ul>
									<a className="btn btn-primary" href="#">Schedule a demo</a>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="key-features-section section-padding">
					<div className="container">
						<div className="row flex-row-reverse">
							<div className="col-12 col-lg-6">
								<img className="img-fluid" src="images/img/img2.svg" alt="" />
							</div>
							<div className="col-12 col-lg-6">
								<div className="pr-60">
									<div className="section-title text-left decoration-left">
										<h2 className="tit">
											<span className="small">Key Features</span>
											Dynamic store-to-door<br></br>order management											
										</h2>
									</div>
									<p>Strip away all complexities across all stages of order management.  Move beyond inefficient, unpredictable order fulfillment to a world of efficiency. Merge omnichannel sales into a single solution that helps you to fulfill orders seamlessly. Your products deserve better. Match your superior products with efficient, technology-driven solutions that drive customer satisfaction. </p>
									<h6>Key Features:</h6>
									<ul className="key-features-list">
										<li><b>Inventory upload</b></li>
										<li><b>Schedule pickups</b></li>
										<li><b>Optimized order fulfilment</b></li>
										<li><b>Cost effective operations</b></li>
									</ul>
									<a className="btn btn-primary" href="#">Schedule a demo</a>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="key-features-section section-padding section-bg">
					<div className="container">
						<div className="row">
							<div className="col-12 col-lg-6">
								<img className="img-fluid" src="images/img/img3.svg" alt="" />
							</div>
							<div className="col-12 col-lg-6">
								<div className="pl-60">
									<div className="section-title text-left decoration-left">
										<h2 className="tit">
											<span className="small">Key Features</span>
											Advanced telematics for<br></br>vehicle management & <br></br> driver behavior										
										</h2>
									</div>
									<p>Keep your fleet in ship shape and monitor the handling of vehicles remotely. Get event-driven triggers to alert you of errant handling and driver behavior.  Pre-empt incidents and maintenance grey areas with powerful features for flawless operations.</p>
									<h6>Key Features:</h6>
									<ul className="key-features-list">
										<li><b>Full visibility - Live tracking of vehicles</b></li>
										<li><b>Vehicle handling – Over speed, harsh braking, harsh cornering, harsh acceleration</b></li>
										<li><b>Optimize fuel - Engine On, Off, Idling, Towing</b></li>
										<li><b>Secure operations - GEO fencing, trip logging</b></li>
										<li><b>Pre-emptive maintenance – Oil change, parts, tire change, Battery Expire, Gear Oil Change, Break Oil Change, Engine Belt check</b></li>																				
									</ul>
									<a className="btn btn-primary" href="#">Schedule a demo</a>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="key-features-section section-padding">
					<div className="container">
						<div className="row flex-row-reverse">
							<div className="col-12 col-lg-6">
								<img className="img-fluid" src="images/img/img41.svg" alt="" />
							</div>
							<div className="col-12 col-lg-6">
								<div className="pr-60">
									<div className="section-title text-left decoration-left">
										<h2 className="tit">
											<span className="small">Key Features</span>
											Full function, feature-packed Admin Module
											
										</h2>
									</div>
									<p>Dispense with the need for special IT training. Empower existing personnel to handle operations effectively.  A thoughtfully designed Admin Module offers intelligent features for every conceivable requirement. Elevate operational capabilities with failsafe cloud-hosted solutions for operation-wide efficiency. Empower employees with technologically superior solutions that transform capabilities.</p>
									<h6>Key Features:</h6>
									<ul className="key-features-list">
										<li><b>Visual, drill-down dashboards for the complete review of operations.</b></li>
										<li><b>User onboarding & management.</b></li>										
										<li><b>Branch, Portfolio Information.</b></li>										
										<li><b>Order management, and report generation.</b></li>										
										<li><b>Customer tracking, visitor analytics, and grievance handling.</b></li>										
										<li><b>Asset management, vehicle, and driver performance analytics.</b></li>										
									</ul>
									<a className="btn btn-primary" href="#">Schedule a demo</a>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="solution-overview-section section-padding">
					<div className="container">
						<div className="section-title text-center">
							<h2 className="tit">
								<span className="small">Solution Overview</span>
								Out of the box, configurable features, for modular integration
							</h2>
						</div>
						<ul className="nav nav-pills">
							<li className="nav-item">
								<a className="nav-link active" data-bs-toggle="tab" href="#driver-platform"><img className="img-fluid" src="images/img/icon/icon07.svg" alt="" />Real-Time Vehicle tracking</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" data-bs-toggle="tab" href="#dispatch-panel"><img className="img-fluid" src="images/img/icon/icon08.svg" alt="" />Driver platform</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" data-bs-toggle="tab" href="#dispatch-platform"><img className="img-fluid" src="images/img/icon/icon09.svg" alt="" />Customer platform</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" data-bs-toggle="tab" href="#customer-experience"><img className="img-fluid" src="images/img/icon/icon10.svg" alt="" />Admin</a>
							</li>
						</ul>
						
						<div className="tab-content">
							<div className="tab-pane container active" id="driver-platform">
								<div className="row flex-row-reverse align-items-center">
									<div className="col-12 col-lg-6">
										<img className="img-fluid" src="images/img/1.svg" alt="" />
									</div>
									<div className="col-12 col-lg-6">
										<div className="section-title text-left decoration-left">
											<h2 className="tit ">
												<span className="small">Real-Time Vehicle tracking</span>
												Real Time Vehicle Tracking
											</h2>
										</div>
										<p>Low latency platform for real time vehicle tracking. Update connected applications and screens with location information. Rely on useful information to track performance, driver behavior and optimize fleet management. Secure merchandise and operations with full visibility.</p>										
									</div>
								</div>
							</div>
							<div className="tab-pane container fade" id="dispatch-panel">
								<div className="row flex-row-reverse align-items-center">
									<div className="col-12 col-lg-6">
										<img className="img-fluid" src="images/img/2.svg" alt="" />
									</div>
									<div className="col-12 col-lg-6">
										<div className="section-title text-left decoration-left">
											<h2 className="tit ">
												<span className="small">Driver Platform</span>
												Driver Platform
											</h2>
										</div>
										<p>Onboard, manage and track your drivers with Driver facing platform. Practical features designed to simplify tasks with compete clarity on requirements. Eliminate ambiguity with crisp notifications, and one touch generation of records. Integrated platform with fast refresh and sync for updated information.</p>										
									</div>
								</div>
								
							</div>
							<div className="tab-pane container fade" id="dispatch-platform">
								<div className="row flex-row-reverse align-items-center">
									<div className="col-12 col-lg-6">
										<img className="img-fluid" src="images/img/3.svg" alt="" />
									</div>
									<div className="col-12 col-lg-6">
										<div className="section-title text-left decoration-left">
											<h2 className="tit ">
												<span className="small">Customer Platform</span>
												Customer Platform
											</h2>
										</div>
										<p>User-friendly Customer facing Platform to perform actions in simple steps. Remove complexities, speed up order placement and fulfillment. Ensure customer delight throughout selection, purchase, review, checkout and tracking process. Instill confidence with thoughtful features.</p>										
									</div>
								</div>
							</div>
							<div className="tab-pane container fade" id="customer-experience">
								<div className="row flex-row-reverse align-items-center">
									<div className="col-12 col-lg-6">
										<img className="img-fluid" src="images/img/4.svg" alt="" />
									</div>
									<div className="col-12 col-lg-6">
										<div className="section-title text-left decoration-left">
											<h2 className="tit ">
												<span className="small">Admin</span>
												Admin
											</h2>
										</div>
										<p>Dashboard packed with actionable information offering master view of operations. Stay in control of day-to-day operations and optimize efficiency with reports and analytics. Customized, expandable, collapsible sections for special emphasis on desired functions and features.</p>										
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="key-benefits-section">
					<div className="container">
						<div className="section-title text-left decoration-left">
							<h2 className="tit">
								Key Benefits
							</h2>
						</div>
						<ul className="benefits-list">
							<li>
								<h4 className="benefits-list-title">Order Management</h4>
								<ul className="benefits-sub-list">
									<li>
										<div className="number">45%</div>
										<h6>Seamless, efficient distribution <br></br>across channels</h6>
									</li>
									<li>
										<div className="number">6+</div>
										<h6>Ensure exceptional relations<br></br>among stakeholders</h6>
									</li>
								</ul>
							</li>
							<li>
								<h4 className="benefits-list-title">Tracking & Analytics</h4>
								<ul className="benefits-sub-list">
									<li>
										<div className="number">23%</div>
										<h6>Track vehicles for <br></br>uninterrupted operations</h6>
									</li>
									<li>
										<div className="number">12%</div>
										<h6>Control assets with <br></br>data-driven management</h6>
									</li>
								</ul>
							</li>
							<li>
								<h4 className="benefits-list-title">Automated Scheduling</h4>
								<ul className="benefits-sub-list">
									<li>
										<div className="number">28%</div>
										<h6>Eliminate delays,<br></br>embrace 24/7 operations</h6>										
									</li>
									<li>
										<div className="number">18%</div>
										<h6>Optimize performance<br></br>with data-led decisions</h6>										
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</section>
				<section className="advantage-section section-padding">
					<div className="container">
						<div className="section-title text-center">
							<h2 className="tit">
								<span className="small">advantage</span>
								Transformative features 
							</h2>
							<p className="m-0">
								We're here to fuel your journey to success, recognized by top-tier organizations globally for our<br></br> unmatched expertise and compelling business value proposition."								
							</p>
						</div>
						<div className="row">
							<div className="col-12 col-lg-5 pr-40">
								<img className="img-fluid" src="images/img/advantages-img.svg" alt="" />
							</div>
							<div className="col-12 col-lg-7">
								<div className="row">
									<div className="col-6 col-sm-4 mb-45">
										<div className="box">
											<img className="img-fluid" src="images/img/icon/icon11.svg" alt="" />
											<h6>Configurable Load planning</h6>
										</div>
									</div>
									<div className="col-6 col-sm-4 mb-45">
										<div className="box">
											<img className="img-fluid" src="images/img/icon/icon12.svg" alt="" />
											<h6>Dispatch Management</h6>
										</div>
									</div>
									<div className="col-6 col-sm-4 mb-45">
										<div className="box">
											<img className="img-fluid" src="images/img/icon/icon13.svg" alt="" />
											<h6>Real-time tracking, analytics</h6>
										</div>
									</div>
									<div className="col-6 col-sm-4">
										<div className="box">
											<img className="img-fluid" src="images/img/icon/icon14.svg" alt="" />
											<h6>Dynamic Route Planning</h6>
										</div>
									</div>
									<div className="col-6 col-sm-4">
										<div className="box">
											<img className="img-fluid" src="images/img/icon/icon15.svg" alt="" />
											<h6>Automated Scheduler</h6>
										</div>
									</div>
									<div className="col-6 col-sm-4">
										<div className="box">
											<img className="img-fluid" src="images/img/icon/icon16.svg" alt="" />
											<h6>eTrip Sheet, e-POD</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
        	</div>
		)
	}
}
export default Home;
