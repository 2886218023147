import React, { useEffect } from 'react';
import { Route, Switch, BrowserRouter, useLocation } from 'react-router-dom'; // Import BrowserRouter and useLocation
import ReactGA from 'react-ga4';

import Home from './Home';
import PaymentThankYou from './pages/payment-thank-you';
import PrivacyPolicy from './pages/privacy-policy';
import TermsCondition from './pages/terms-condition';
import Header from './Header';
import Footer from './Footer';

const TRACKING_ID = "G-G873XH2ZS1";

const TrackingComponent = () => {
    const location = useLocation(); // useLocation works here inside BrowserRouter
    
    useEffect(() => {
        ReactGA.initialize(TRACKING_ID);
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location]);

    return null; // This component just handles tracking
};

const AppRoutes = () => {
    return (
        // Moved BrowserRouter to wrap everything
        <BrowserRouter>       
            <TrackingComponent /> {/* Handles the location tracking */}
            <Header />    
            <Switch>
                <Route path="/policy/" component={PrivacyPolicy} />      
                <Route path="/term/" component={TermsCondition} />      
                <Route path="/success/" component={PaymentThankYou} exact />      
                <Route path="/" component={Home} />     
            </Switch>                                  
            <Footer />
        </BrowserRouter>
    );
};

export default AppRoutes;
