import React from 'react';

const Footer = (props) => {
    const currentYear = new Date().getFullYear();
    return(
        <footer className="footer-main">
            <div className="top-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-7">
                            <h2>Sign Up For A Customized Demo</h2>
                            <a className="btn btn-primary demo-btn" href="#">Schedule a demo</a>
                        </div>
                        <div className="col-12 col-lg-5">
                            <div className="two-part">
                                {/* <div className="email-part">
                                    <div className="ft-list-title">
                                        <h4>Email Us</h4>
                                    </div>
                                    <ul className="email-list">
                                        <li>
                                            <label>For Enquiries</label>
                                            <a href="mailto:info@becls.com">info@becls.com</a>
                                        </li>
                                        <li>
                                            <label>To Partner With Us</label>
                                            <a href="mailto:partner@becls.com">partner@becls.com</a>
                                        </li>
                                        <li>
                                            <label>For Support</label>
                                            <a href="mailto:Support@becls.com">Support@becls.com</a>
                                        </li>
                                    </ul>
                                </div> */}
                                <div className="location-part">
                                    <div className="ft-list-title">
                                        <h4>Location</h4>
                                    </div>

                                    <p className="address">Office #13, alghadi Tower<br></br>alshohada, Riyadh exit 9<br></br> KSA.</p>
                                    {/* <div className="follow-part">
                                        <h5>Follow Us On</h5>
                                        <ul className="social-list">
                                            <li><a href="#"><i className="fa-brands fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
                                            <li><a href="#"><i className="fa-brands fa-whatsapp"></i></a></li>
                                            <li><a href="#"><i className="fa-brands fa-linkedin-in"></i></a></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <div className="container">
                    <p className="copyright-text">©{currentYear} becls.com. All rights reserved.</p>
                </div>
            </div>
        </footer>
    )

}

export default Footer;