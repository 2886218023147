import React, { useEffect } from 'react';
import { useField } from 'formik';
import PhoneInput from 'react-phone-number-input';

const PhoneInputField = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props.name);

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  useEffect(() => {
    if (props.defaultCountry) {       
      helpers.setValue('');
      helpers.setTouched(false);
    }
  }, [props.defaultCountry]);

  return (
    <div>
      
      <PhoneInput
        {...field}
        {...props}
        international
        countryCallingCodeEditable={false}       
        onBlur={(e) => {
            handleBlur();
            field.onBlur(e);
          }}
        className='form-control'
        onChange={(value) => {            
            helpers.setValue(value);            
        }}
      />
      {meta.error ? (
        <span className="error-message">{meta.error}</span>
      ) : null}
    </div>
  );
};

export default PhoneInputField;
